
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'
import { IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonLoading } from '@ionic/vue'

import SpinoramaLogo from '@/components/header/SpinoramaLogo.vue'

export default defineComponent({
  name: 'Login',
  components: {
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonLoading,
    SpinoramaLogo,
  },
  setup() {
    const email = ref('')
    const password = ref('')
    const router = useRouter()
    const store = useStore()
    const loading = ref(false)
    function onSubmit() {
      // submit to backend or whatever you like
      loading.value = true
      store.dispatch('login', { email, password }).then(res => {
        store.commit('saveToken', { token: res.token, user: res.user })
        loading.value = false
        router.push('tabs')
      })
    }
    return {
      email,
      password,
      loading,
      onSubmit,
    }
  },
})
