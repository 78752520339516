<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-grid>
        <spinorama-logo size="big" />
        <ion-loading :is-open="loading" />
        <ion-row>
          <img src="@/assets/img/hockeyplayer.png" alt="hockey player image" />
        </ion-row>
        <form id="loginForm" @submit.prevent="onSubmit">
          <ion-item lines="full">
            <ion-label position="floating" color="light">
              {{ $t('E-mail') }}
            </ion-label>
            <ion-input v-model="email" type="email" />
          </ion-item>
          <ion-item lines="full">
            <ion-label position="floating" color="light">
              {{ $t('Password') }}
            </ion-label>
            <ion-input v-model="password" type="password" />
          </ion-item>
          <ion-button
            type="sumbit"
            color="secondary"
            expand="block"
          >
            {{ $t('Login') }}
          </ion-button>
        </form>
        <p class="text-align-center small-text">
          {{ $t('Not a member yet? Sign&nbsp;up') }} <span class="arrow">&#8594;</span>
        </p>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'
import { IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonLoading } from '@ionic/vue'

import SpinoramaLogo from '@/components/header/SpinoramaLogo.vue'

export default defineComponent({
  name: 'Login',
  components: {
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonLoading,
    SpinoramaLogo,
  },
  setup() {
    const email = ref('')
    const password = ref('')
    const router = useRouter()
    const store = useStore()
    const loading = ref(false)
    function onSubmit() {
      // submit to backend or whatever you like
      loading.value = true
      store.dispatch('login', { email, password }).then(res => {
        store.commit('saveToken', { token: res.token, user: res.user })
        loading.value = false
        router.push('tabs')
      })
    }
    return {
      email,
      password,
      loading,
      onSubmit,
    }
  },
})
</script>

<style scoped>
h1 {
  margin-top: 50px;
  margin-bottom: 20px;
}

form {
  margin: 20px auto;
  max-width: 600px;
  padding: 0 20px;
}

ion-button {
  margin-top: 30px;
}

.white-container {
  height: 95%;
}

img {
  padding: 0 20px;
  width: 90%;
  max-width: 320px;
  margin: 30px auto;
}
</style>
